import { render, staticRenderFns } from "./BowCoverPreview.vue?vue&type=template&id=630925bd&"
import script from "./BowCoverPreview.vue?vue&type=script&lang=js&"
export * from "./BowCoverPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowImage: require('/workspace/components/atoms/BowImage/BowImage.vue').default,BowOverlay: require('/workspace/components/atoms/BowOverlay/BowOverlay.vue').default,BowHeading: require('/workspace/components/atoms/BowHeading/BowHeading.vue').default,BowCallToAction: require('/workspace/components/molecules/BowCallToAction/BowCallToAction.vue').default,BowFeatureGroup: require('/workspace/components/molecules/BowFeatureGroup/BowFeatureGroup.vue').default,BowLoadingBar: require('/workspace/components/atoms/BowLoadingBar/BowLoadingBar.vue').default})

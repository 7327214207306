//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";
import { BuildData, checkBreakpoint, defaultBlockProps } from "~/helpers";

export default {
    name: "BowCoverPreview",

    props: {
        ...defaultBlockProps,

        post: {
            default: null,
            type: Object,
        },

        theme: {
            default: null,
            type: String,
        },
    },

    data: () => ({
        features: null,
        image: null,
        componentTitle: null,
        postTitle: null,
        typeImmobile: null,
        video: null,
        url: null,
    }),

    async fetch() {
        if (this.post === null) {
            const currentRoute = this.$router.currentRoute.path;
            const isImmobile = String(currentRoute).search(/\/immobili/) !== -1;

            if (isImmobile === false) {
                this.postTitle = this.$t("Tutti gli immobili");
                this.componentTitle = null;
                this.typeImmobile = null;
                this.image = null;
                this.url = "/immobili";

                return;
            }

            const immobiliCount =
                this.$store.getters["immobili/GET_IMMOBILI_COUNT"];
            if (immobiliCount === 0) {
                await this.$store.dispatch("immobili/UPDATE_IMMOBILI", null);
            }

            const currentImmobile = new BuildData(this.immobile, "immobile");
            const data = currentImmobile.build();

            const currentIndex = [...this.immobili].findIndex((val, idx) => {
                return val.slug === data.slug;
            });

            const post = this.immobili[currentIndex + 1];

            this.features = post.services;
            this.componentTitle = post.nomeImmobile;
            this.typeImmobile = post.tipologiaImmobile;
            this.postTitle = post.title;
            this.image = post.featuredImage;

            this.url = `/${post.slug}`;

            if (this.typeImmobile) {
                this.url = `/immobili/${post.slug}`;
            }

            return;
        }

        const resultAPI = await this.$axios.$get(`posts/${this.post.id}`, {
            params: {
                culture: this.$store.app.i18n.locale,
            },
        });

        if (resultAPI.isSuccess === false) {
            return;
        }

        const post = resultAPI.payload.posts[0];

        const bd = new BuildData(post, this.isImmobile ? "immobile" : null);
        const bdResponse = bd.build();

        this.features = bdResponse.services;
        this.componentTitle = bdResponse.nomeImmobile;
        this.typeImmobile = bdResponse.tipologiaImmobile;
        this.postTitle = bdResponse.title;
        this.image = bdResponse.featuredImage;

        this.url = `/${bdResponse.slug}`;

        if (this.typeImmobile) {
            this.url = `/immobili/${bdResponse.slug}`;
        }
    },

    computed: {
        ...mapState({
            immobile: ({ immobile }) => immobile.data,
            immobili: ({ immobili }) => immobili.immobili,
            scroll: ({ scroll }) => scroll.scroll,
        }),

        ...mapGetters([
            "scroll/currentScroll",
            "immobile/getData",
            "immobili/GET_IMMOBILI",
            "immobili/GET_IMMOBILI_COUNT",
        ]),

        isLoaderActive() {
            if (checkBreakpoint.down("xl")) {
                return false;
            }

            let windowInnerHeight = 0;
            if (typeof window !== "undefined") {
                windowInnerHeight = window.innerHeight;
            }

            if (
                this.scroll.current.y + windowInnerHeight >=
                this.offsetHeight
            ) {
                return true;
            } else {
                return false;
            }
        },

        getTheme() {
            switch (this.theme) {
                case "secondary":
                    return "bg-secondary/20";
                case "primary":
                default:
                    return "bg-primary";
            }
        },

        getTextColor() {
            switch (this.theme) {
                case "secondary":
                    return "text-primary";
                case "primary":
                default:
                    return "text-white";
            }
        },
    },

    mounted() {
        this.offsetHeight = Math.round(
            document.querySelector(".b-scroll__wrapper").getBoundingClientRect()
                .height
        );

        this.observeWrapper = new ResizeObserver(() => {
            this.offsetHeight = Math.round(
                document
                    .querySelector(".b-scroll__wrapper")
                    .getBoundingClientRect().height
            );

            // this.$nuxt.$emit('update-locomotive');
        });

        this.observeWrapper.observe(
            document.querySelector(".b-scroll__wrapper")
        );

        // this.$nuxt.$emit('update-locomotive');
    },

    destroyed() {
        this.observeWrapper.unobserve(
            document.querySelector(".b-scroll__wrapper")
        );

        this.observeWrapper = null;
        this.offsetHeight = null;
    },

    methods: {
        ...mapActions(["immobili/UPDATE_IMMOBILI"]),
    },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:['o-cover-preview', 'relative w-full']},[_c('div',{staticClass:"absolute h-full left-0 top-0 w-full z-0"},[(_vm.image && _vm.image.image)?_c('div',{staticClass:"block h-full relative w-full"},[_c('BowImage',{attrs:{"classPicture":"absolute block h-full left-0 top-0 w-full z-0","classImage":"h-full object-cover relative w-full","alt":_vm.image.alt,"image":_vm.image.image,"radius":false}}),_vm._v(" "),_c('BowOverlay',{attrs:{"className":"absolute h-full left-0 top-0 w-full z-1"}})],1):_c('div',{class:[_vm.getTheme, 'h-full relative w-full']},[_c('div',{staticClass:"absolute top-1/2 left-0 overflow-hidden transform -translate-y-1/2 w-full h-full z-0"},[_c('img',{staticClass:"h-full object-cover transform",attrs:{"src":require("assets/liberty-icon.svg")}})])])]),_vm._v(" "),_c('div',{staticClass:"min-h-[300px] px-6 relative z-1 lg:min-h-[600px] lg:px-16 xl:px-[136px]"},[_c(_vm.url && _vm.url !== '' && _vm.features ? 'NuxtLink' : 'div',{tag:"component",class:[
                'block ',
                _vm.features
                    ? 'pt-80 pb-20 lg:py-60'
                    : 'py-20 text-center lg:py-60' ],attrs:{"to":_vm.url && _vm.url !== '' && _vm.features ? _vm.localePath(_vm.url) : null}},[_c('div',{class:[
                    'text-center',
                    _vm.getTextColor,
                    // 'md:flex md:items-center md:justify-between md:text-left',
                    _vm.features
                        ? 'md:grid md:grid-cols-5 md:items-end'
                        : 'flex flex-col items-center justify-center min-h-[200px]' ]},[(_vm.typeImmobile && _vm.componentTitle)?_c('div',{class:[
                        'w-full md:z-10',
                        _vm.features
                            ? 'md:col-start-1 md:col-end-3 md:text-left'
                            : 'md:text-center' ]},[_c('BowHeading',{attrs:{"size":"8xl","weight":"font-bold"}},[_c('span',{staticClass:"block text-3xl"},[_vm._v(_vm._s(_vm.$t(_vm.typeImmobile)))]),_vm._v(" "),_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.componentTitle))])])],1):(_vm.postTitle && _vm.postTitle !== '')?_c('div',{class:[
                        'w-full md:z-10',
                        _vm.features
                            ? 'md:col-start-1 md:text-left md:col-end-3'
                            : 'md:text-center' ]},[_c('BowHeading',{attrs:{"size":"8xl","weight":"font-bold"}},[_c('span',{staticClass:"block"},[_vm._v(_vm._s(_vm.postTitle))])]),_vm._v(" "),(_vm.url && _vm.url !== '')?_c('div',{staticClass:"inline-flex items-center justify-center mx-auto mt-8"},[_c('BowCallToAction',{attrs:{"cta":[
                                {
                                    link: _vm.url,
                                    text_link: _vm.$t('Continua'),
                                    title_link: _vm.postTitle,
                                    type_button: 'Link interno',
                                } ],"hollow":true,"theme":_vm.theme === 'primary' ? 'white' : 'primary'}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.features)?_c('div',{staticClass:"w-full md:col-start-4 md:col-end-6 md:text-right md:z-10"},[_c('BowFeatureGroup',{staticClass:"hidden lg:flex",attrs:{"isCoverPreview":true,"services":_vm.features}})],1):_vm._e()])])],1),_vm._v(" "),(_vm.url && _vm.url !== '')?_c('BowLoadingBar',{attrs:{"isActive":_vm.isLoaderActive,"redirect":_vm.url,"theme":_vm.theme === 'secondary' ? 'primary' : 'secondary'}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
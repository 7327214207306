//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from "vuex";

export default {
    name: "BowLoadingBar",
    props: {
        className: {
            default: null,
            type: String,
        },
        htmlTag: {
            default: null,
            type: String,
        },
        isActive: {
            default: false,
            type: Boolean,
        },
        redirect: {
            default: "",
            type: String,
        },
        theme: {
            default: null,
            type: String,
        },
    },

    data: () => ({
        counter: 0,
        intervalCounter: null,
        width: 0,
    }),

    computed: {
        ...mapState({
            counterLoader: ({ ui }) => ui.counterLoader,
        }),

        ...mapGetters(["ui/GET_COUNTER_LOADER"]),

        getTransform() {
            if (this.isActive === false) {
                return "scaleX(0)";
            }

            if (this.counter >= 100) {
                this.activeRedirect();

                return "scaleX(1)";
            }

            return `scaleX(${this.counter / 100})`;
        },

        getTheme() {
            switch (this.theme) {
                case "secondary":
                    return "bg-secondary";
                case "primary":
                default:
                    return "bg-primary";
            }
        },
    },

    mounted() {
        this.width = Math.round(this.$el.getBoundingClientRect().width);

        // let ms = 0;
        this.intervalCounter = setInterval(() => {
            if (this.isActive === true) {
                this.counter += 3;
                // ms += 100;
                // console.log(ms);
                return;
            }

            this.counter = 0;
            // ms = 0;

            // console.log(this.isActive, this.counter);
        }, 100);
    },

    destroyed() {
        if (this.intervalCounter) {
            clearInterval(this.intervalCounter);

            this.intervalCounter = null;
        }
    },

    methods: {
        ...mapActions(["ui/UPDATE_COUNTER_LOADER"]),

        activeRedirect() {
            this.$router.push(this.localePath(this.redirect));
        },
    },
};

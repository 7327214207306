var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list && _vm.list.length > 0)?_c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:[
        _vm.isCover === true
            ? 'flex flex-row flex-wrap gap-3 justify-center lg:gap-x-0.5 lg:gap-y-0 lg:justify-between xl:gap-x-2 2xl:gap-x-3'
            : '',
        // ? 'grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] sm:grid-cols-2 2xl:grid-cols-[repeat(auto-fill,_minmax(245px,_1fr))]'
        _vm.isCoverPreview === true
            ? 'flex flex-row flex-wrap justify-center w-full sm:justify-center'
            : '',
        _vm.isCover === false && _vm.isCoverPreview === false
            ? 'flex flex-row flex-wrap justify-center w-full sm:justify-center'
            : '' ]},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
            _vm.isCover === true ? 'px-2 pt-6 2xl:px-2 2xl:pt-7' : '',
            _vm.isCoverPreview === true ? 'px-6 2xl:px-4 2xl:py-7' : '',
            _vm.isCover === false && _vm.isCoverPreview === false
                ? 'p-3 w-[150px] sm:w-[190px] sm:p-6 2xl:p-7'
                : '' ]},[_c('BowFeature',{class:[
                'w-full',
                _vm.isCover == true || _vm.isCoverPreview === true
                    ? ''
                    : 'space-y-2' ],attrs:{"icon":item.icon,"label":item.label,"size":_vm.isCover === true || _vm.isCoverPreview === true
                    ? 'text-5xl'
                    : 'text-7xl'}})],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
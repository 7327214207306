//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BowFeature',
    props: {
        htmlTag: {
            default: null,
            type: String,
        },
        icon: {
            default: null,
            type: String,
        },
        label: {
            default: '',
            type: String,
        },
        size: {
            default: 'text-7xl',
            type: String,
        },
    },
    data: () => ({}),
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'BowIconIdromassaggio',
    props: {
        pathClassname: {
            default: 'fill-secondary stroke-0',
            type: String,
        },
    },
};

import { render, staticRenderFns } from "./BowFeature.vue?vue&type=template&id=f7c9e980&"
import script from "./BowFeature.vue?vue&type=script&lang=js&"
export * from "./BowFeature.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BowIconIdromassaggio: require('/workspace/components/atoms/BowIcons/BowIconIdromassaggio.vue').default,BowIcons: require('/workspace/components/atoms/BowIcons/BowIcons.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "BowOverlay",
    props: {
        className: {
            default: null,
            type: String,
        },
        color: {
            default: "black",
            type: String,
        },
        htmlTag: {
            default: null,
            type: String,
        },
    },
    data: () => ({}),
    computed: {
        getColor() {
            switch (this.color) {
                case "white":
                    return "bg-white/30";
                case "primary":
                    return "bg-primary/30";
                case "secondary":
                    return "bg-secondary/30";
                case "black":
                default:
                    return "bg-black/30";
            }
        },
    },
};

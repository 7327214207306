//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { themeValidation } from "~/helpers";

export default {
    name: "BowCallToAction",
    props: {
        className: {
            default: null,
            type: String,
        },
        componentType: {
            default: "default",
            type: String,
            validator: (value) => ["default", "circle"].includes(value),
        },
        cta: {
            default: null,
            type: Array,
        },
        hollow: {
            default: false,
            type: Boolean,
        },
        htmlTag: {
            default: null,
            type: String,
        },
        paddingButtons: {
            default: true,
            type: Boolean,
        },
        theme: {
            default: "primary",
            type: String,
            validator: (value) => themeValidation(value),
        },
    },

    data: () => ({
        list: [],
    }),

    async fetch() {
        await this.manageList();
    },

    methods: {
        async getLink(link, type) {
            switch (type) {
                case "Link interno":
                    if (String(link).search(/^[0-9]+$/) !== -1) {
                        if (typeof this.$getSlugByID !== "undefined") {
                            const slug = await this.$getSlugByID(Number(link));

                            if (slug) {
                                return `/${slug}`;
                            }
                        }

                        return "/";
                    }

                    return String(link).search(/^\//) !== -1
                        ? link
                        : `/${link}`;

                case "Link esterno":
                    return link;

                case "Ancora":
                    return String(link).search(/^#/) !== -1 ? link : `#${link}`;

                case "Email":
                    return `mailto:${link}`;

                case "Telefono":
                    return `tel:${link}`;

                case "Whatsapp":
                    return `tel:${link}`;

                case "Allegato":
                    return link;

                default:
                    return link;
            }
        },

        getType(type) {
            switch (type) {
                case "Link interno":
                    // case 'Ancora' :
                    return "link";

                case "Link esterno":
                case "Allegato":
                case "Email":
                case "Telefono":
                case "Whatsapp":
                    return "link-ext";

                case "Ancora":
                    return "link-classic";

                default:
                    return "button";
            }
        },

        async manageList() {
            if (this.cta && this.cta.length > 0) {
                await Promise.all(
                    this.cta.map(async (item, idx) => {
                        const link = await this.getLink(
                            item.link,
                            item.type_button
                        );
                        this.list.push({
                            ...item,
                            link,
                        });
                    })
                );
            }
        },

        handleAnchor(type, event) {
            if (event) {
                event.preventDefault();
            }

            if (type !== "Ancora") {
                return;
            }

            // console.log("ancora");

            this.$emit("scrollTo", event.target.getBoundingClientRect().top);
            // this.$locomotive.scrollTo();
        },
    },
};

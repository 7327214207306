var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.htmlTag ? _vm.htmlTag : 'div',{tag:"component",class:[
        'a-loading-bar',
        _vm.className
            ? _vm.className
            : 'absolute bottom-0 h-1 left-0 overflow-hidden w-full',
        _vm.isActive === false ? 'pointer-events-none' : '' ]},[_c('div',{class:[
            'bg-white duration-300 h-full overflow-hidden transition-all w-full',
            _vm.isActive === false ? 'translate-y-full' : 'translate-y-0' ]},[_c('div',{class:[
                _vm.getTheme,
                'duration-300 h-full origin-left transition-all w-full' ],style:({
                transform: _vm.getTransform,
            })})])])}
var staticRenderFns = []

export { render, staticRenderFns }
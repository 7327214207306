//
//
//
//
//
//
//
//
//

import tailwindConf from "#tailwind-config";

export default {
    name: "BowHeading",
    props: {
        align: {
            default: "",
            type: String,
        },
        customSize: {
            default: "",
            type: String,
        },
        size: {
            default: "",
            type: String,
        },
        tag: {
            default: null,
            validator: (value) => [1, 2, 3, 4, 5, 6].includes(value),
            type: Number,
        },
        weight: {
            default: "font-light",
            type: String,
            // validator: (value) => ['font-light', 'font-bold'].includes(value),
        },
    },
    data: () => ({}),
    computed: {
        getSize() {
            let size = "text-base";

            if (this.customSize !== "") {
                size = this.customSize;

                return size;
            }

            // Manage size of text
            if (this.size !== "") {
                Object.entries(tailwindConf.theme.fontSize).forEach(
                    ([key, value]) => {
                        if (this.size === key) {
                            switch (this.size) {
                                case "xs":
                                    size = "text-xs";
                                    break;

                                case "sm":
                                    size = "text-sm";
                                    break;

                                case "md":
                                case "base":
                                case "":
                                    size = "text-base";
                                    break;

                                case "lg":
                                    size = "text-base lg:text-lg";
                                    break;

                                case "xl":
                                    size = "text-lg lg:text-xl";
                                    break;

                                case "2xl":
                                    size = "text-xl lg:text-2xl";
                                    break;

                                case "3xl":
                                    size = "text-xl lg:text-2xl 2xl:text-3xl";
                                    break;

                                case "4xl":
                                    size = "text-2xl lg:text-3xl 2xl:text-4xl";
                                    break;

                                case "5xl":
                                    size = "text-3xl lg:text-4xl 2xl:text-5xl";
                                    break;

                                case "6xl":
                                    size = "text-4xl lg:text-5xl 2xl:text-6xl";
                                    break;

                                case "7xl":
                                    size = "text-5xl lg:text-6xl 2xl:text-7xl";
                                    break;

                                case "8xl":
                                    size =
                                        "text-5xl md:text-6xl lg:text-7xl 2xl:text-8xl";
                                    break;

                                default:
                                    size = "text-2xl 2xl:text-4xl";
                                    break;
                            }
                        }
                    }
                );
            }

            return size;
        },
    },
};

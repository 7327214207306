//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import servizi from "~/config/servizi";

export default {
    name: "BowFeatureGroup",
    props: {
        htmlTag: {
            default: null,
            type: String,
        },
        isCover: {
            default: false,
            type: Boolean,
        },
        isCoverPreview: {
            default: false,
            type: Boolean,
        },
        services: {
            default: null,
            type: Object,
        },
    },

    data: () => ({
        list: [],
    }),

    fetch() {
        const order = [];
        servizi.forEach((ser) => {
            order.push(ser.key);
        });

        const tempList = [];
        if (this.services && Object.entries(this.services).length > 0) {
            let canRegisterGarden = false;
            let canRegisterPark = false;

            // console.log(this.services);

            Object.entries(this.services).forEach(([key, item]) => {
                const idxOrder = order.indexOf(key, 0);

                if (idxOrder === -1) {
                    return;
                }

                if (key === "ospiti_filtri") {
                    return;
                }

                if (key === "citta" && this.isCover === false) {
                    return;
                }

                if (
                    (this.isCover === true || this.isCoverPreview === true) &&
                    key !== "citta" &&
                    key !== "ospiti" &&
                    key !== "camere" &&
                    key !== "bagni"
                ) {
                    return;
                }

                if (key === "giardino") {
                    if (item.value && item.value === "1") {
                        canRegisterGarden = true;
                    }

                    return;
                }

                if (
                    key === "superficieGiardino" &&
                    canRegisterGarden === false
                ) {
                    return;
                }

                if (key === "parcheggioPrivato") {
                    if (item.value && item.value === "1") {
                        canRegisterPark = true;
                    }

                    return;
                }

                if (
                    key === "parcheggioPrivatoTipologia" &&
                    canRegisterPark === false
                ) {
                    return;
                }

                if (
                    (item.value === "0" || item.value === null) &&
                    key !== "parcheggioPrivatoTipologia"
                ) {
                    return;
                }

                tempList[idxOrder] = {
                    ...item,
                    key,
                };
            });
        }

        if (tempList.length > 0) {
            tempList.forEach((item) => {
                if (item) {
                    const filteredServices = servizi.filter(
                        (el) => el.key === item.key
                    );
                    const currentService = filteredServices[0];

                    let value = "";
                    if (
                        item.value !== "1" &&
                        item.value !== "0" &&
                        item.key !== "parcheggioPrivatoTipologia"
                    ) {
                        value = item.value;
                    }

                    let label = "";
                    if (item.label !== null) {
                        label = this.$t(item.label);

                        if (currentService && currentService.space === true) {
                            label = ` ${this.$t(item.label)}`;
                        }
                    }

                    let valueEnd = "";
                    // if ( item.key === 'parcheggioPrivatoTipologia' ) {
                    //   valueEnd = item.value ? item.value : '';
                    // }
                    valueEnd = "";

                    this.list.push({
                        ...item,
                        label: `${value}${label}${valueEnd}`,
                    });
                }
            });
        }
    },
};
